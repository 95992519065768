import { render, staticRenderFns } from "./BackGroImage.vue?vue&type=template&id=6ef76576&scoped=true"
import script from "./BackGroImage.vue?vue&type=script&lang=js"
export * from "./BackGroImage.vue?vue&type=script&lang=js"
import style0 from "./BackGroImage.vue?vue&type=style&index=0&id=6ef76576&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ef76576",
  null
  
)

export default component.exports