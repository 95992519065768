import { render, staticRenderFns } from "./galleryCom.vue?vue&type=template&id=9c55ed8c&scoped=true"
import script from "./galleryCom.vue?vue&type=script&lang=js"
export * from "./galleryCom.vue?vue&type=script&lang=js"
import style0 from "./galleryCom.vue?vue&type=style&index=0&id=9c55ed8c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c55ed8c",
  null
  
)

export default component.exports