<template>
  <div>
    <HeaderComponent />
    <div class="blog">
      <div class="containers">
        <div class="row">
          <div class="contentWidth">
            <!-- Blog 1 Content -->
            <div v-if="img1" class="col-lg-12 col-md-12 col-sm-12">
              <h2>{{$t('message.crossBorderInformationOrocedure')}}</h2>
              <div class="blog-img">
                <img
                  src="https://kalelogistics.com/usa/wp-content/uploads/2023/11/MicrosoftTeams-image-20-scaled.jpg"
                  alt=""
                />
              </div>
              <div class="blog-content">
                <h4>{{$t('message.crossBorderTitle1')}}</h4>
                <p>{{$t('message.crossBorderText1')}}</p>
                <h4>{{$t('message.crossBorderTitle2')}}</h4>
                <p>{{$t('message.crossBorderText2')}}</p>
                <h4>{{$t('message.crossBorderTitle3')}}</h4>
                <p>{{$t('message.crossBorderText3')}}</p>
                <h4>{{$t('message.crossBorderTitle4')}}</h4>
                <p>{{$t('message.crossBorderText4')}}</p>
                <h4>{{$t('message.crossBorderTitle5')}}</h4>
                <p>{{$t('message.crossBorderText5')}}</p>
                <h4>{{$t('message.crossBorderTitle6')}}</h4>
                <p>{{$t('message.crossBorderText6')}}</p>
                <h4>{{$t('message.crossBorderTitle7')}}</h4>
                <p>{{$t('message.crossBorderText7')}}</p>
                <p>{{$t('message.crossBorderText71')}}</p>
                <h4>{{$t('message.crossBorderTitle8')}}</h4>
                <p>{{$t('message.crossBorderText8')}}</p>
                <h4>{{$t('message.crossBorderTitle9')}}</h4>
                <p>{{$t('message.crossBorderText9')}}</p>
                <h4>{{$t('message.crossBorderTitle10')}}</h4>
                <p>{{$t('message.crossBorderText10')}}</p>
                <h4>{{$t('message.crossBorderTitle11')}}</h4>
                <h4>{{$t('message.crossBorderTitle111')}}</h4>
                <p>{{$t('message.crossBorderText11')}}</p>
                <p>{{$t('message.crossBorderText111')}}</p>
                <h4>{{$t('message.crossBorderTitle12')}}</h4>
                <p>{{$t('message.crossBorderText12')}}</p>
                <p>{{$t('message.crossBorderText121')}}</p>
              </div>
            </div>

            <!-- Blog 2 Content -->
            <div v-if="img2" class="col-lg-12 col-md-12 col-sm-12">
              <h2>{{$t('message.siemReaptitle1')}}</h2>
              <div class="blog-img">
                <img
                  src="@/assets/carousel/advs-new-route.jpg"
                  alt=""
                />
              </div>
              <div class="blog-content">
                <p>{{$t('message.siemReaptitle2')}}</p>
                <h1>{{$t('message.siemReaptitle3')}}</h1>
                <p>{{$t('message.siemReaptitle4')}}</p>
                <h1>{{$t('message.siemReaptitle5')}}</h1>
                <p>{{$t('message.siemReaptitle6')}}</p>
                <ul>
                  <li > <strong>{{ $t('message.siemReaptitle7') }}</strong>  {{ $t('message.siemReaptitle8') }} </li>
                  <li > <strong>{{ $t('message.siemReaptitle9') }}</strong>  {{ $t('message.siemReaptitle10') }} </li>
                  <li > <strong>{{ $t('message.siemReaptitle11') }}</strong>  {{ $t('message.siemReaptitle12') }} </li>
                  <li > <strong>{{ $t('message.siemReaptitle13') }}</strong>  {{ $t('message.siemReaptitle14') }} </li>
                  <li > <strong>{{ $t('message.siemReaptitle15') }}</strong>  {{ $t('message.siemReaptitle16') }} </li>
                  <li > <strong>{{ $t('message.siemReaptitle17') }}</strong>  {{ $t('message.siemReaptitle18') }} </li>
                </ul>
                <h1>{{$t('message.siemReaptitle19')}}</h1>
                <p>{{$t('message.siemReaptitle20')}}</p>
                <ol>
                  <li><strong>{{$t('message.siemReaptitle21')}}</strong> {{$t('message.siemReaptitle22')}}</li>
                  <li><strong>{{$t('message.siemReaptitle23')}}</strong> {{$t('message.siemReaptitle24')}}</li>
                  <li><strong>{{$t('message.siemReaptitle25')}}</strong> {{$t('message.siemReaptitle26')}}</li>
                  <li><strong>{{$t('message.siemReaptitle27')}}</strong> {{$t('message.siemReaptitle28')}}</li>
                </ol>
                <h1>{{$t('message.siemReaptitle29')}}</h1>
                <p>{{$t('message.siemReaptitle30')}}</p>
                <br>
                <p>{{$t('message.siemReaptitle31')}}</p>
                <ul>
                  <li>{{$t('message.siemReaptitle32')}}</li>
                  <li>{{$t('message.siemReaptitle33')}}</li>
                  <li>{{$t('message.siemReaptitle34')}}</li>
                  <li>{{$t('message.siemReaptitle35')}}</li>
                  <li>{{$t('message.siemReaptitle36')}}</li>
                </ul>
                <p>{{$t('message.siemReaptitle37')}}</p>
                <ul>
                  <li>{{$t('message.siemReaptitle38')}}</li>
                  <li>{{$t('message.siemReaptitle39')}}</li>
                </ul>
                <h1>{{$t('message.siemReaptitle40')}}</h1>
                <p>{{$t('message.siemReaptitle41')}}</p> 
                <p style="background-color: #DE5D0A  ; font-weight: 600" padding: 4px>{{$t('message.siemReaptitle42')}}</p>
                <p style="background-color: #DE5D0A  ; font-weight: 600" padding: 4px>{{$t('message.siemReaptitle43')}}</p>
                <!-- Add Blog 2 specific content -->
              </div>
            </div>
            <!-- Blog 3 Content -->
            <div v-if="img3" class="col-lg-12 col-md-12 col-sm-12">
              <h2>{{$t('message.battambangTitle1')}}</h2>
              <div class="blog-img">
                <img
                  src="@/assets/carousel/advs-new-route.jpg"
                  alt=""
                />
              </div>
              <div class="blog-content">
                <p>{{$t('message.battambangTitle2')}}</p>
                <h3>{{$t('message.battambangTitle3')}}</h3>
                <p>{{$t('message.battambangTitle4')}}</p>
                <h3>{{$t('message.battambangTitle5')}}</h3>
                <p>{{$t('message.battambangTitle6')}}</p>
                <ul>
                  <li><strong>{{$t('message.battambangTitle7')}}</strong> {{$t('message.battambangTitle8')}}</li>
                  <li><strong>{{$t('message.battambangTitle9')}}</strong> {{$t('message.battambangTitle10')}}</li>
                  <li><strong>{{$t('message.battambangTitle11')}}</strong> {{$t('message.battambangTitle12')}}</li>
                  <li><strong>{{$t('message.battambangTitle13')}}</strong> {{$t('message.battambangTitle14')}}</li>
                  <li><strong>{{$t('message.battambangTitle15')}}</strong> {{$t('message.battambangTitle16')}}</li>
                  <li><strong>{{$t('message.battambangTitle17')}}</strong> {{$t('message.battambangTitle18')}}</li>
                </ul>

                <h3>{{$t('message.battambangTitle19')}}</h3>
                <p>{{$t('message.battambangTitle20')}}</p>
                <ol>
                  <li><strong>{{$t('message.battambangTitle21')}}</strong> {{$t('message.battambangTitle22')}}</li>
                  <li><strong>{{$t('message.battambangTitle23')}}</strong> {{$t('message.battambangTitle24')}}</li>
                  <li><strong>{{$t('message.battambangTitle25')}}</strong> {{$t('message.battambangTitle26')}}</li>
                  <li><strong>{{$t('message.battambangTitle27')}}</strong> {{$t('message.battambangTitle28')}}</li>
                </ol>
                <h3>{{$t('message.battambangTitle29')}}</h3>
                <p>{{$t('message.battambangTitle30')}}</p>
                <p>{{$t('message.battambangTitle31')}}</p>
                <ul>
                  <li>{{$t('message.battambangTitle32')}}</li>
                  <li>{{$t('message.battambangTitle33')}}</li>
                  <li>{{$t('message.battambangTitle34')}}</li>
                  <li>{{$t('message.battambangTitle35')}}</li>
                  <li>{{$t('message.battambangTitle36')}}</li>
                </ul>
                <p>{{$t('message.battambangTitle37')}}</p>
                <ul>
                  <li>{{$t('message.battambangTitle38')}}</li>
                  <li>{{$t('message.battambangTitle39')}}</li>
                </ul>
                <h3>{{$t('message.battambangTitle40')}}</h3>
                <p>{{$t('message.battambangTitle41')}}</p>
                <p style="background-color: #DE5D0A;  font-weight: 600; padding: 4px">{{$t('message.battambangTitle42')}}</p>
                <p style="background-color: #DE5D0A; font-weight: 600; padding: 4px">{{$t('message.battambangTitle43')}}</p>
              </div>
            </div>
            <!-- Blog 4 Content -->
            <div v-if="img4" class="col-lg-12 col-md-12 col-sm-12">
              <h2>{{$t('message.sihanoukvilleTitle1')}}</h2>
              <div class="blog-img">
                <img
                  src="@/assets/carousel/advs-new-route.jpg"
                  alt=""
                />
              </div>
              <div class="blog-content">
                <p>{{ $t('message.sihanoukvilleTitle2') }}</p>
                  <h3>{{ $t('message.sihanoukvilleTitle3') }}</h3>
                  <P>{{ $t('message.sihanoukvilleTitle4') }}</P>
                  <h3>{{ $t('message.sihanoukvilleTitle5') }}</h3>
                  <p>{{ $t('message.sihanoukvilleTitle6') }}</p>
                  <ul>
                    <li><strong>{{$t('message.sihanoukvilleTitle7')}}</strong> {{ $t('message.sihanoukvilleTitle8') }}</li>
                    <li><strong>{{$t('message.sihanoukvilleTitle9')}}</strong> {{ $t('message.sihanoukvilleTitle10') }}</li>
                    <li><strong>{{$t('message.sihanoukvilleTitle11')}}</strong> {{ $t('message.sihanoukvilleTitle12') }}</li>
                    <li><strong>{{$t('message.sihanoukvilleTitle13')}}</strong> {{ $t('message.sihanoukvilleTitle14') }}</li>
                    <li><strong>{{$t('message.sihanoukvilleTitle15')}}</strong> {{ $t('message.sihanoukvilleTitle16') }}</li>
                    <li><strong>{{$t('message.sihanoukvilleTitle17')}}</strong> {{ $t('message.sihanoukvilleTitle18') }}</li>
                  </ul>
                  <h3>{{ $t('message.sihanoukvilleTitle19') }}</h3>
                  <p>{{ $t('message.sihanoukvilleTitle20') }}</p>
                  <ol>
                    <li><strong>{{ $t('message.sihanoukvilleTitle21') }}</strong>{{ $t('message.sihanoukvilleTitle22') }}</li>
                    <li><strong>{{ $t('message.sihanoukvilleTitle23') }}</strong>{{ $t('message.sihanoukvilleTitle24') }}</li>
                    <li><strong>{{ $t('message.sihanoukvilleTitle25') }}</strong>{{ $t('message.sihanoukvilleTitle26') }}</li>
                    <li><strong>{{ $t('message.sihanoukvilleTitle27') }}</strong>{{ $t('message.sihanoukvilleTitle28') }}</li>
                  </ol>
                  <h3>{{ $t('message.sihanoukvilleTitle29') }}</h3>
                  <p>{{ $t('message.sihanoukvilleTitle30') }}</p>
                  <p>{{ $t('message.sihanoukvilleTitle31') }}</p>
                  <p>{{ $t('message.sihanoukvilleTitle32') }}</p>
                  <ul>
                    <li>{{ $t('message.sihanoukvilleTitle33') }}</li>
                    <li>{{ $t('message.sihanoukvilleTitle34') }}</li>
                    <li>{{ $t('message.sihanoukvilleTitle35') }}</li>
                    <li>{{ $t('message.sihanoukvilleTitle35') }}</li>
                    <li>{{ $t('message.sihanoukvilleTitle36') }}</li>
                  </ul>
                  <p>{{ $t('message.sihanoukvilleTitle37') }}</p>
                  <ul>
                    <li>{{ $t('message.sihanoukvilleTitle38') }}</li>
                    <li>{{ $t('message.sihanoukvilleTitle39') }}</li>
                  </ul>
                  <h3>{{ $t('message.sihanoukvilleTitle40') }}</h3>
                  <p>{{ $t('message.sihanoukvilleTitle41') }}</p>
                  <p style="background-color: #DE5D0A;  font-weight: 600; padding: 4px">{{$t('message.sihanoukvilleTitle42')}}</p>
                  <p style="background-color: #DE5D0A; font-weight: 600; padding: 4px">{{$t('message.sihanoukvilleTitle43')}}</p>
              </div>
            </div>
            <!-- Blog 5 Content -->
            <div v-if="img5" class="col-lg-12 col-md-12 col-sm-12">
              <h2>{{$t('message.KratieTitle1')}}</h2>
              <div class="blog-img">
                <img
                  src="@/assets/carousel/advs-new-route.jpg"
                  alt=""
                />
              </div>
              <div class="blog-content">
                <p>{{ $t('message.KratieTitle2') }}</p>
                <p>{{ $t('message.KratieTitle3') }}</p>
                <h3>{{ $t('message.KratieTitle4') }}</h3>
                <p>{{ $t('message.KratieTitle5') }}</p>
                <h3>{{ $t('message.KratieTitle6') }}</h3>
                <p>{{ $t('message.KratieTitle7') }}</p>
                <ul>
                  <li><strong>{{ $t('message.KratieTitle8') }} </strong>{{ $t('message.KratieTitle9') }}</li>
                  <li><strong>{{ $t('message.KratieTitle10') }} </strong>{{ $t('message.KratieTitle11') }}</li>
                  <li><strong>{{ $t('message.KratieTitle12') }} </strong>{{ $t('message.KratieTitle13') }}</li>
                  <li><strong>{{ $t('message.KratieTitle14') }} </strong>{{ $t('message.KratieTitle15') }}</li>
                  <li><strong>{{ $t('message.KratieTitle16') }} </strong>{{ $t('message.KratieTitle17') }}</li>
                  <li><strong>{{ $t('message.KratieTitle18') }} </strong>{{ $t('message.KratieTitle19') }}</li>
                </ul>
                <h3>{{ $t('message.KratieTitle20') }}</h3>
                <p>{{ $t('message.KratieTitle21') }}</p>
                <ol>
                  <li><strong>{{ $t('message.KratieTitle22') }} </strong>{{ $t('message.KratieTitle23') }}</li>
                  <li><strong>{{ $t('message.KratieTitle24') }} </strong>{{ $t('message.KratieTitle25') }}</li>
                  <li><strong>{{ $t('message.KratieTitle26') }} </strong>{{ $t('message.KratieTitle27') }}</li>
                  <li><strong>{{ $t('message.KratieTitle28') }} </strong>{{ $t('message.KratieTitle29') }}</li>
                </ol>
                <h3>{{ $t('message.KratieTitle30') }}</h3>
                <p>{{ $t('message.KratieTitle31') }}</p>
                <p>{{ $t('message.KratieTitle32') }}</p>
                <ul>
                  <li>{{ $t('message.KratieTitle33') }}</li>
                  <li>{{ $t('message.KratieTitle34') }}</li>
                  <li>{{ $t('message.KratieTitle35') }}</li>
                  <li>{{ $t('message.KratieTitle36') }}</li>
                  <li>{{ $t('message.KratieTitle37') }}</li>
                </ul>
                <p>{{ $t('message.KratieTitle38') }}</p>
                <ul>
                  <li>{{ $t('message.KratieTitle39') }}</li>
                  <li>{{ $t('message.KratieTitle40') }}</li>
                </ul>
                <h3>{{ $t('message.KratieTitle41') }}</h3>
                <p>{{ $t('message.KratieTitle42') }}</p>
                <p style="background-color: #DE5D0A;  font-weight: 600; padding: 4px">{{$t('message.KratieTitle43')}}</p>
                <p style="background-color: #DE5D0A; font-weight: 600; padding: 4px">{{$t('message.KratieTitle44')}}</p>
              </div>
            </div>
            <!-- Blog 6 Content -->
            <div v-if="img6" class="col-lg-12 col-md-12 col-sm-12">
              <h2>{{$t('message.laos1')}}</h2>
              <div class="blog-img">
                <img
                  src="@/assets/carousel/advs-new-route.jpg"
                  alt=""
                />
              </div>
              <div class="blog-content">
                <p>{{ $t('message.laos2') }}</p>
                <a href="https://youtu.be/Fs5ZW8w0xN8?si=CW-NN2oiOAyCa8cX">{{ $t('message.laos3') }}</a>
                <h3>{{ $t('message.laos4') }}</h3>
                <p>{{ $t('message.laos5') }}</p>
                <h3>{{ $t('message.laos6') }}</h3>
                <p>{{ $t('message.laos7') }}</p>
                <ul>
                  <li><strong>{{ $t('message.laos8') }} </strong>{{ $t('message.laos9') }}</li>
                  <li><strong>{{ $t('message.laos10') }} </strong>{{ $t('message.laos11') }}</li>
                  <li><strong>{{ $t('message.laos12') }} </strong>{{ $t('message.laos13') }}</li>
                  <li><strong>{{ $t('message.laos14') }} </strong>{{ $t('message.laos15') }}</li>
                  <li><strong>{{ $t('message.laos16') }} </strong>{{ $t('message.laos17') }}</li>
                  <li><strong>{{ $t('message.laos18') }} </strong>{{ $t('message.laos19') }}</li>
                </ul>
                <a href="https://youtu.be/bRYF3HWDEP8?si=dEqbA39nX5MQzDkT">{{ $t('message.laos20') }}</a>
                <h3>{{ $t('message.laos21') }}</h3>
                <p>{{ $t('message.laos22') }}</p>
                <ol>
                  <li><strong>{{ $t('message.laos23') }}</strong> {{ $t('message.laos24') }}</li>
                  <li><strong>{{ $t('message.laos25') }}</strong> {{ $t('message.laos26') }}</li>
                  <li><strong>{{ $t('message.laos27') }}</strong> {{ $t('message.laos28') }}</li>
                  <li><strong>{{ $t('message.laos29') }}</strong> {{ $t('message.laos30') }}</li>
                </ol>
                <h3>{{ $t('message.laos31') }}</h3>
                <p>{{ $t('message.laos32') }}</p>
                <p>{{ $t('message.laos33') }}</p>
                <p>{{ $t('message.laos34') }}</p>
                <ul>
                  <li>{{ $t('message.laos35') }}</li>
                  <li>{{ $t('message.laos36') }}</li>
                  <li>{{ $t('message.laos37') }}</li>
                  <li>{{ $t('message.laos38') }}</li>
                  <li>{{ $t('message.laos39') }}</li>
                </ul>
                <p>{{ $t('message.laos40') }}</p>
                <ul>
                  <li>{{ $t('message.laos41') }}</li>
                  <li>{{ $t('message.laos42') }}</li>
                </ul>
                <h3>{{ $t('message.laos43') }}</h3>
                <p>{{ $t('message.laos44') }}</p>
                <p style="background-color: #DE5D0A;  font-weight: 600; padding: 4px">{{$t('message.laos45')}}</p>
                <p style="background-color: #DE5D0A; font-weight: 600; padding: 4px">{{$t('message.laos46')}}</p>
              </div>
            </div>
            <!-- Blog 7 Content -->
                <div v-if="img7" class="col-lg-12 col-md-12 col-sm-12">
              <h2>{{$t('message.laos1')}}</h2>
              <div class="blog-img">
                <img
                  src="@/assets/carousel/advs-new-route.jpg"
                  alt=""
                />
              </div>
              <div class="blog-content">
               <p>{{ $t('message.OnlineShoppingTitle1') }}</p>
               <h3>{{ $t('message.OnlineShoppingTitle2') }}</h3>
               <ol>
                <li><strong>{{ $t('message.OnlineShoppingTitle3') }}</strong></li>
                <p>{{ $t('message.OnlineShoppingTitle4') }}</p>
                <li><strong>{{ $t('message.OnlineShoppingTitle5') }}</strong></li>
                <p>{{ $t('message.OnlineShoppingTitle6') }}</p>
                <li><strong>{{ $t('message.OnlineShoppingTitle7') }}</strong></li>
                <p>{{ $t('message.OnlineShoppingTitle8') }}</p>
                <li><strong>{{ $t('message.OnlineShoppingTitle9') }}</strong></li>
                <p>{{ $t('message.OnlineShoppingTitle10') }}</p>
               </ol>
                <h3>{{ $t('message.OnlineShoppingTitle11') }}</h3>
                <p>{{ $t('message.OnlineShoppingTitle12') }}</p>
              </div>
            </div>
            <!-- Back to Blogs Button -->
            <!-- <div class="back-button" @click="goBackToBlogs">
              <span>← {{$t('message.backToBlogs')}}</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <IncFooter />
  </div>
</template>

<script>
import HeaderComponent from "@/components/Headers/HeaderComponent.vue";
import IncFooter from "@/components/footer/IncFooter.vue";

export default {
  components: { HeaderComponent, IncFooter },
  data() {
    return {
      img1: false,
      img2: false,
      img3: false,
      img4: false,
      img5: false,
      img6: false,
      img7: false,
    };
  },
  methods: {
    goBackToBlogs() {
      localStorage.removeItem("blog-img");
      this.$router.push("/blog");
    }
  },
  created() {
    const blogId = localStorage.getItem("blog-img");
    this.img1 = blogId === "1";
    this.img2 = blogId === "2";
    this.img3 = blogId === "3";
    this.img4 = blogId === "4";
    this.img5 = blogId === "5";
    this.img6 = blogId === "6";
    this.img7 = blogId === "7";
    
    // Redirect if no blog is selected
    if (!blogId) {
      this.$router.push('/blog');
    }
  },
  beforeDestroy() {
    localStorage.removeItem("blog-img");
  }
};
</script>

<style scoped>
.blog {
  margin-top: 140px;
  padding-bottom: 50px;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentWidth {
  width: 75%;
  position: relative;
}

.blog-img {
  width: 100%;
  height: 380px;
  margin: 20px 0px;
}

.blog-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.blog-content {
  margin-top: 20px;
}

.back-button {
  position: fixed;
  bottom: 30px;
  left: 30px;
  background: #DE5D0A;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
  
}

.back-button:hover {
  transform: translateX(-5px);
}

/* Responsive Styles */
@media (max-width: 960px) {
  .blog-img {
    padding-left: 0;
    padding-right: 0;
  }
  
  .back-button {
    position: static;
    margin-top: 30px;
    text-align: center;
  }
}

@media (max-width: 450px) {
  .blog {
    margin-top: 100px;
  }
  
  .contentWidth {
    width: 100%;
    padding: 0 15px;
  }
  
  .blog-img {
    height: 240px;
  }
  
  h4 {
    font-size: 18px;
  }
  
  p {
    font-size: 15px;
  }
}
</style>