<template>
  <div class="vet-back">
    <HeaderComponent />
    <div class="containers">
      <div class="position">
        <div class="ceo-text">
          <div class="bg-color-fdede4"></div>
          <div class="bg-e88a4d">
            <div>
              {{$t('message.neakOknhaSuoVireak')}}<br />
              {{$t('message.founderChairmanCEO')}}
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-3"></div>
            <div class="col-lg-9 col-md-9 col-sm-9" style="padding-top: 50px">
              <p class="set-font">
                {{$t('message.desVetBackground1')}}
              </p>
            </div>
          </div>
        </div>
        <div class="img-ceo">
          <img src="@/assets/Neak Oknha Vireak Suo.png" alt="" />
        </div>
      </div>
      <div class="show-hide">
        <div>
          {{$t('message.neakOknhaSuoVireak')}}<br />
          {{$t('message.founderChairmanCEO')}}
        </div>
      </div>
      <div class="tex-descri" style="margin-bottom: 50px">
        <p class="set-font">
          {{$t('message.desVetBackground2')}}
        </p>
        <p class="set-font">
          {{$t('message.desVetBackground3')}}
        </p>
        <p class="set-font">
          {{$t('message.desVetBackground4')}}
        </p>
        <p class="set-font">
          {{$t('message.desVetBackground5')}}
        </p>
      </div>
    </div>

    <IncFooter />
  </div>
</template>

<script>
import HeaderComponent from "@/components/Headers/HeaderComponent.vue";
import IncFooter from "@/components/footer/IncFooter.vue";
export default {
  components: { HeaderComponent, IncFooter },
  methods:{
    scrollToTop() {
      window.scrollTo(0,0);
    },
  },
  created(){
    this.scrollToTop();
  }
};
</script>

<style scoped>
.vet-back {
  height: 100%;
  margin-top: 120px;
  /* padding-bottom: 50px; */
}
.position {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 500px;
  padding-left: 50px;
  padding-right: 50px;
}
.ceo-text {
  padding-left: 100px;
  width: 100%;
  height: 140px;
}
.bg-color-fdede4 {
  width: 100%;
  height: 50px;
  background: #fdede4;
}
.bg-e88a4d {
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e88a4d;
}
.text {
  display: flex;
}
.img-ceo {
  position: absolute;
  top: 0;
  left: 0;
}
.img-ceo img {
  width: 350px;
}
.tex-descri {
  margin-top: 50px;
}
.set-font {
  font-size: 15px;
  line-height: 26px;
}
.set-font span {
  font-size: 18px;
  font-weight: 500;
}
.set-font .spanColor {
  color: #de5d0a;
  font-size: 18px;
  font-weight: 500;
}
.show-hide {
  display: none;
  font-weight: 600;
  color: #ffffff;
  padding: 40px;
  background: #e88a4d;
  font-size: 20px;
}
@media (width < 1000px) {
  .vet-back {
    margin-top: 0px;
  }
  .img-ceo img {
    width: 260px;
  }
  .img-ceo {
    position: absolute;
    top: 30%;
    left: 0;
  }
}

@media (width < 750px) {
  .vet-back {
    margin-top: -40px;
  }
  .show-hide {
    display: block;
  }
  .ceo-text {
    display: none;
  }
}
@media (width < 626px) {
  .vet-back {
    margin-top: -40px;
  }
  .tex-descri {
    margin-top: 50px;
  }
}
@media (width < 450px) {
  .vet-back {
    margin-top: -40px;
  }
  .set-font {
    font-size: 14px;
  }
}
</style>