<!-- <template>
  <div class="blog">
    <div class="containers">
     
      <p class="title-blogs-cambodia">{{ $t('message.JourneyGuideInCambodia') }}</p>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog1"
              src="https://kalelogistics.com/usa/wp-content/uploads/2023/11/MicrosoftTeams-image-20-scaled.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog1">
            <p style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">{{$t('message.crossBorderInformationOrocedure')}}</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog2"
              src="@/assets/carousel/advs-new-route.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog2">
            <p style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">{{$t('message.siemReaptitle1')}}</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog3"
              src="@/assets/carousel/advs-new-route.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog3">
            <p style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">{{$t('message.battambangTitle1')}}</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog4"
              src="@/assets/carousel/advs-new-route.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog4">
            <p>{{$t('message.sihanoukvilleTitle1')}}</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog5"
              src="@/assets/carousel/advs-new-route.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog5">
            <p>{{$t('message.KratieTitle1')}}</p>
          </div>
        </div>
      </div>
      <p class="title-blogs-laos">{{ $t('message.JourneyGuideInLaos') }}</p>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog6"
              src="https://kalelogistics.com/usa/wp-content/uploads/2023/11/MicrosoftTeams-image-20-scaled.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog6">
            <p>{{$t('message.laos1')}}</p>
          </div>
        </div>
      </div>
      <p class="title-blogs-laos">{{ $t('message.Lifestyle') }}</p>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog7"
              src="@/assets/carousel/ads.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog7">
            <p>{{$t('message.OnlineShopping')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    blog1() {
      localStorage.setItem("blog-img", 1);
      this.$router.push("/blog-detail");
    },
    blog2() {
      localStorage.setItem("blog-img", 2);
      this.$router.push("/blog-detail");
    },
    blog3() {
      localStorage.setItem("blog-img", 3);
      this.$router.push("/blog-detail");
    },
    blog4() {
      localStorage.setItem("blog-img", 4);
      this.$router.push("/blog-detail");
    },
    blog5() {
      localStorage.setItem("blog-img", 5);
      this.$router.push("/blog-detail");
    },
    blog6() {
      localStorage.setItem("blog-img", 6);
      this.$router.push("/blog-detail");
    },
    blog7() {
      localStorage.setItem("blog-img", 7);
      this.$router.push("/blog-detail");
    }
  },
  created() {},
};
</script>

<style scoped>
/* .blog {
  height: 90vh;
} */
.title-blogs-cambodia {
  margin-top: 120px;
  font-size: 30px;
  font-weight: 600;
}
.title-blogs-laos {
  font-size: 30px;
  font-weight: 600;
}
.card {
  margin-top: 20px;
  cursor: pointer;
}
.blog-img {
  width: 100%;
  height: 240px;
}
.blog-img img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-blog {
  cursor: pointer;
  width: 100%;
  height: 220px;
  border-radius: 12px;
  overflow: hidden;
}
.img-blog img {
  width: 100%;
  height: 100%;
}
.groupText {
  padding-top: 8px;
}
.groupText p:hover {
  color: #de5d0a;
}
@media (min-width: 1740px) {
  .set-heights {
    height: 500px;
  }
}
.no-result {
  margin-top: 15px;
  font-size: 25px;
  font-weight: 600;
}
@media (width < 1024px) {
  .set-heights {
    height: 500px;
  }
}
@media (width < 450px) {
  .blog {
    height: 70vh;
  }
  .row {
    margin-top: 50px;
  }
  .no-result {
    font-size: 16px;
  }
  img {
    width: 86px;
  }
  .blog-img {
    height: 220px;
    border-radius: 6px;
  }
  .groupText p {
    font-size: 15px;
  }
}
</style> -->

<template>
  <div class="blog">
    <div class="containers">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog1"
              src="https://kalelogistics.com/usa/wp-content/uploads/2023/11/MicrosoftTeams-image-20-scaled.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog1">
            <p>{{$t('message.crossBorderInformationOrocedure')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    blog1() {
      localStorage.setItem("blog-img", 1);
      this.$router.push("/blog-detail");
    },
    blog2() {
      localStorage.setItem("blog-img", 2);
      this.$router.push("/blog-detail");
    },
    blog3() {
      localStorage.setItem("blog-img", 3);
      this.$router.push("/blog-detail");
    },
  },
  created() {},
};
</script>

<style scoped>
.blog {
  height: 90vh;
}
.row {
  margin-top: 120px;
}
.card {
  margin-top: 20px;
  cursor: pointer;
}
.blog-img {
  width: 100%;
  height: 240px;
}
.blog-img img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-blog {
  cursor: pointer;
  width: 100%;
  height: 220px;
  border-radius: 12px;
  overflow: hidden;
}
.img-blog img {
  width: 100%;
  height: 100%;
}
.groupText {
  padding-top: 8px;
}
.groupText p:hover {
  color: #de5d0a;
}
@media (min-width: 1740px) {
  .set-heights {
    height: 500px;
  }
}
.no-result {
  margin-top: 15px;
  font-size: 25px;
  font-weight: 600;
}
@media (width < 1024px) {
  .set-heights {
    height: 500px;
  }
}
@media (width < 450px) {
  .blog {
    height: 70vh;
  }
  .row {
    margin-top: 50px;
  }
  .no-result {
    font-size: 16px;
  }
  img {
    width: 86px;
  }
  .blog-img {
    height: 220px;
    border-radius: 6px;
  }
  .groupText p {
    font-size: 15px;
  }
}
</style>